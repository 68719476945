import React from 'react'; 

import './blogpage.styles.scss'; 

import { NavLink, withRouter } from 'react-router-dom';
import CustomButton from '../form/custom-button/custom-button.component';

import Logo from '../../assets/images/Logo_150x50Inverted.png';
import BannerImage from '../../assets/images/banner.jpeg';

class BlogPage extends React.Component {
    render () {
        const { history } = this.props;
        return (
            <div className="press-page">
                <div className='header'>
                    <NavLink to='/' className='logo-container'>
                        <img src={Logo} className='logo' alt='logo' loading='eager' height='50'/>
                    </NavLink>
                </div>

                <div className="banner">
                    <img className="banner-image" src={BannerImage} height="550" alt="Empower your diversity leaders today"/>

                    <div className="banner-text">
                        <h4>Blog</h4>
                        <h1>Posts</h1>
                        <CustomButton type="button" isVentButton visibility onClick={() => { history.replace('/') }}> Back to Home Page</CustomButton>
                    </div>
                </div>

                <div className="container view-height">
                    <h3>What is Synergie Builing</h3>
                    <p>90% of fortune 500 companies today have Employee Resource Groups also known as business resource groups. ERGs are groups created by employees voluntarily around a variety of issues. Whether it’s gender, race, sexual preference or mental health, they are an invaluable resource to foster inclusive workplaces. Companies benefit from retaining and recruiting top diverse talent while increasing performance and enhancing leadership skills of their employees.</p>
                    <p>ERGs are led by passionate volunteer employees. However, most ERGs fail to meet their diversity and inclusion goals because attendance and engagement is low, it requires significant amount of time investment in organizing & engaging groups, and enterprise leaders want to see the return on investment before allocate time or budget to these leaders. </p>
                    <p>That's why my company SynERGie is building an internal communication tool for Employee Resource Groups where members and allies can to share their concerns in a productive manner. They can even do so pseudonymously, creating a safe place to air ideas. With SynERGie, ERG leaders save time using a single tool to organize and engage members which they automatically track and can then share reports with upper management to acquire more resources for their groups. </p>
                    <p>We're devoted to safeguarding employee private information, that's why SynERGie uses the latest technologies in text, audio and video synthesis to completely change personas online. This allows real issues to surface without having to worry about job security. </p>
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div className='copyright'> © 2021 SynERGie Tech, Inc </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(BlogPage);
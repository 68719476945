import React from 'react';

import './custom-button.styles.scss'; 

const CustomButton = ({ children, isSmall, isGoogleSignIn, isTwitterSignIn, isAnonymousSignIn, isVentButton, isSuccessButton, visibility, hide, inverted, ...otherProps }) => {
    return (
        <button className={`${inverted ? 'inverted' : ''} 
                            ${isSmall ? 'small': ''}
                            ${isGoogleSignIn ? 'google-sign-in' : ''} 
                            ${isAnonymousSignIn ? 'anonymous-sign-in' : ''} custom-button 
                            ${isVentButton ? 'custom-vent-button' : ''}
                            ${isSuccessButton ? 'custom-success-button' : ''} 
                            ${visibility ? 'visible' : 'hidden'}
                            ${hide ? 'hide' : 'display'}
                            `} 
                            
                            {...otherProps}>
            { children }
        </button>
    )
}

export default CustomButton; 
import React from 'react'; 

import './presspage.styles.scss'; 

import { NavLink, withRouter } from 'react-router-dom';
import CustomButton from '../form/custom-button/custom-button.component';

import Logo from '../../assets/images/Logo_150x50Inverted.png';
import LogoLarge from '../../assets/images/Logo_600x200.png';
import LogoInverted from '../../assets/images/Logo_600x200Inverted.png';
import BannerImage from '../../assets/images/banner.jpeg';
import TewodrosImage from '../../assets/images/twProfile.jpeg'; 
import MonicaImage from '../../assets/images/mpProfile.jpeg';
import LaurenImage from '../../assets/images/lclProfile.jpeg';

class PressPage extends React.Component {
    render () {
        const { history } = this.props;
        return (
            <div className="press-page">
                <div className='header'>
                    <NavLink to='/' className='logo-container'>
                        <img src={Logo} className='logo' alt='logo' loading='eager' height='50'/>
                    </NavLink>
                </div>

                <div className="banner">
                    <img className="banner-image" src={BannerImage} height="550" alt="Empower your diversity leaders today"/>
                    <video className="banner-video" height="550" autoPlay loop>
                        {/* <source src={}></source> */}
                    </video>
                    <div className="banner-text">
                        <h4>Blog</h4>
                        <h1>Press Material</h1>
                        <CustomButton type="button" isVentButton visibility onClick={() => { history.replace('/') }}> Back to Home Page</CustomButton>
                    </div>
                </div>

                <div className="container">
                    <h3>Company Description Boilerplate</h3>
                    <p>SynERGie is building an internal communication tool for Employee Resource Groups where members and allies can to share their concerns in a productive manner. They can even do so pseudonymously, creating a safe place to air ideas. With SynERGie, ERG leaders save time using a single tool to organize and engage members which they automatically track and can then share reports with upper management to acquire more resources for their groups. </p>
                </div>
                <div className="container">
                    <h3>Company Branding</h3>
                    <div className="container-grid">
                        <div className="grid-item">
                            <img src={LogoLarge} alt='generate text' loading='eager'/>
                            <h3> <b> Logo </b></h3>
                            <p> To be used on white background</p>
                        </div>
                        <div className="grid-item">
                            <img src={LogoInverted} alt='change voice genders' loading='eager'/>
                            <h3> <b> Inverted Logo </b></h3>
                            <p> To be used on a green (#3FAA21) background </p>
                        </div>
                    </div>                
                </div>
                <div className="container">
                    <h3>Team Bio</h3>
                    <div className="container-grid">
                        <div className="grid-item">
                            <img src={TewodrosImage} alt='Tewodros Wondimu' loading='eager'/>
                            <h3> <b> Tewodros Wondimu </b></h3>
                            <h4> Founder and CEO </h4>
                            <p> Tewodros Wondimu is a Software Engineer with over 6 years professional work experience who is passionate about startups, UI/UX design, object-oriented development and agile development methodologies.  </p>
                        </div>
                        <div className="grid-item">
                            <img src={LaurenImage} alt='Lauren Chan Lee' loading='eager'/>
                            <h3> <b> Lauren Chan Lee </b></h3>
                            <h4> Advisor </h4>
                            <p>Lauren loves building things, whether it's a new product, customer program, or line of business. Her career started in strategy, but she was so excited by the growth strategies she was recommending that she decided she had to be a part of the execution and moved over to the operating side of the business. Her approach to her products with a general manager mindset and strive to inspire her team of PMs, engineers, designers, and business to create the best experiences for our users.  </p>
                        </div>
                        <div className="grid-item">
                            <img src={MonicaImage} alt='Monica Phillips' loading='eager'/>
                            <h3> <b> Monica Phillips </b></h3>
                            <h4> Advisor </h4>
                            <p> With more than 20 years of experience leading marketing and client teams for some of the largest law firms, she has support her clients to create thriving and inclusive workplaces with peak performance through leadership, mindfulness, and emotional wellbeing.  </p>
                        </div>
                    </div>                
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div className='copyright'> © 2021 SynERGie Tech, Inc </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PressPage);
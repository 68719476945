
import './App.scss';
import { Switch, Route } from 'react-router-dom'; 

import HomePage from './components/homepage/homepage.component';
import PressPage from './components/presspage/presspage.component';
import BlogPage from './components/blogpage/blogpage.component';
import TermsPage from './components/termspage/termspage.component';
import PolicyPage from './components/policypage/policypage.component';

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path='/' exact component={HomePage}/>
        <Route path='/blog' exact component={BlogPage}/>
        <Route path='/blog/press' exact component={PressPage}/>
        {/* <Route path='/legal' exact component={LegalPage}/> */}
        <Route path='/legal/terms_of_use' exact component={TermsPage}/>
        <Route path='/legal/privacy_policy' exact component={PolicyPage}/>
      </Switch>
    </div>
  );
}

export default App;

import firebase from 'firebase/app';
import 'firebase/analytics';

export const firebaseConfig = {
  "apiKey": "AIzaSyD_aWy0lwI86f6I-Z9UDB_IbUWiKGvmOzE",
  "appId": "1:967350585006:web:e1a344cd870ee325670f3f",
  "authDomain": "synergie-tech.firebaseapp.com",
  "databaseURL": "",
  "measurementId": "G-FBDF3Q4Q3T",
  "messagingSenderId": "967350585006",
  "projectId": "synergie-tech",
  "storageBucket": "synergie-tech.appspot.com"
};

firebase.initializeApp(firebaseConfig);

export default firebase;

import React from 'react';
import { NavLink, withRouter } from 'react-router-dom';

import './homepage.styles.scss';

import CustomButton from '../form/custom-button/custom-button.component';

import GenerativeTextImage from '../../assets/images/generativeText.jpeg';
import GenderChangeImage from '../../assets/images/genderChange.jpeg';
import DeepfakeImage from '../../assets/images/deepfake.jpeg';
import FILogoImage from '../../assets/images/fiLogo.png';
import MerchSpread from '../../assets/images/spreadSmall.png';
import Logo from '../../assets/images/Logo_150x50Inverted.png';
import BannerImage from '../../assets/images/banner.jpeg';
import EngageImage from '../../assets/images/engageSmall.png';
import OrganizeImage from '../../assets/images/organizeSmall.png';
import TrackImage from '../../assets/images/trackSmall.png';
import DiscussOpenly from '../../assets/images/DiscussOpenly.png';
import DiscoverConnect from '../../assets/images/DiscoverConnect.png';
import ManageEvents from '../../assets/images/ManageEvents.png';
import Contributions from '../../assets/images/Contribute.png';
import HireDiverse from '../../assets/images/DiverseTalent.png';
import Metrics from '../../assets/images/Metrics.png';

class HomePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            EMAIL: '',
            FNAME: '',
            LNAME: ''
        }
        this.howItWorksRef = React.createRef();
        this.expressYourselfRef = React.createRef();
        this.featuresRef = React.createRef();
        this.psedoRef = React.createRef();
        this.enterpriseRef = React.createRef();
        this.anonymousRef = React.createRef();
        this.merchRef = React.createRef();
        this.subscribeRef = React.createRef();
    }

    scrollTo(element) {
        if (element) {
            element.scrollIntoView({ behavior: "smooth", top: element.scrollHeight})
        }
    }

    // takes an event, gets the target value and name and sets it to the state, this is to allow
    // form component to be reused
    handleChange = event => {
        // pull the value and name of the event target which is the input value
        const { value, name } = event.target;

        // then set the state of that name to the value
        this.setState({ [name]: value })
    }

    handleSubmit = event => {
        this.setState({
            EMAIL: '',
            FNAME: '',
            LNAME: ''
        })
    }

    render() {
        const { history } = this.props;

        return (
            <div className="home-page">
                <div className='header'>
                    <NavLink to='/' className='logo-container'>
                        <img src={Logo} className='logo' alt='logo' loading='eager' height='50'/>
                    </NavLink>
                    <div className='options'>
                        <div className='option' onClick={() => { this.scrollTo(this.howItWorksRef) }}>
                            How it works
                        </div>
                        <div className='option' onClick={() => { this.scrollTo(this.featuresRef) }}>
                            Features
                        </div>
                        <div className='option' onClick={() => { this.scrollTo(this.psedoRef) }}>
                            Psedonomous
                        </div>
                        <div className='option' onClick={() => { this.scrollTo(this.merchRef) }}>
                            Merch
                        </div>
                        <div className='option' onClick={() => { this.scrollTo(this.subscribeRef) }}>
                            Subscribe
                        </div>
                        <div className='option' onClick={() => { history.replace('/blog') }}>
                            Blog
                        </div>
                        <div className='option' onClick={() => { history.replace('/blog/press') }}>
                            Press
                        </div>
                    </div>
                </div>

                <div className="banner">
                    <img className="banner-image" src={BannerImage} height="550" alt="Empower your diversity leaders today"/>
                    <video className="banner-video" height="550" autoPlay loop>
                        {/* <source src={}></source> */}
                    </video>
                    <div className="banner-text">
                        <h1>Empower your diversity leaders today</h1>
                        <h3>Help you ERGs reach your diversity and inclusion goals </h3>
                        <CustomButton type="button" isVentButton visibility onClick={() => { this.scrollTo(this.howItWorksRef) }}> Learn More </CustomButton>
                    </div>
                </div>
                <div className="container container-gray" ref={ref => this.howItWorksRef = ref}>
                    <h4> How it Works </h4>
                    <h2> Diversity isn't distraction from work, it is the work. </h2>
                    <h3> Diversity, Equity, Inclusion & Belonging is more important than ever; it affects your bottom line, it’s not just an other item to check off, it needs to be part of your DNA </h3>
                    <div className="container-grid">
                        <div className="grid-item">
                            <img src={EngageImage} alt='personal' loading='eager'/>
                            <h3> <b> Engage </b></h3>
                            <h3> Members and allies can discuss topics safely, discover other ERGs and members  </h3>
                        </div>
                        <div className="grid-item">
                            <img src={OrganizeImage} alt='school' loading='eager'/>
                            <h3> <b> Organize </b></h3>
                            <h3> Save time with event RSVPs, contribution, opportunities and assigning mentors </h3>
                        </div>
                        <div className="grid-item">
                            <img src={TrackImage} alt='enterprise' loading='eager'/>
                            <h3> <b> Track </b></h3>
                            <h3> Automatically track attendance, engagement, growth and more </h3>
                        </div>
                    </div>
                </div>

                <div className="container" ref={ref => this.featuresRef = ref}>
                    <h2>Here are somethings you’ll love about SynERGie </h2>
                    <h3> We have all the features your ERG needs to succeed in reaching your goals.</h3>
                </div>

                <div className="container">
                    <div className="container-grid-duo">
                        <div className="left">
                            <h2>Discuss Openly</h2>
                            <h3>Most employees are hesitant to share how they really feel for fear of retaliation or that their workplace won’t take action even if they do, with SynERGie employees can post comfortably with different privacy options </h3>

                        </div>
                        <img src={DiscussOpenly} className="small" alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>

                <div className="container" >
                    <div className="container-grid-duo switch-direction">
                        <div className="left">
                            <h2>Discover</h2>
                            <h3>Connect with other employees who share the same characteristics and those that are allies, find other groups to join and find the right mentorship through SynERGie discovery</h3>

                        </div>
                        <img src={DiscoverConnect} alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>

                <div className="container" >
                    <div className="container-grid-duo">
                        <div className="left">
                            <h2>Manage Events</h2>
                            <h3>With digital RSVPs, event registration, check in and more so that you have more time to focus on what is important to your group.</h3>

                        </div>
                        <img src={ManageEvents} alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>

                <div className="container" >
                    <div className="container-grid-duo switch-direction">
                        <div className="left">
                            <h2>Contributions</h2>
                            <h3>SynERGie makes it super simple to collect contributions from members and allies to make it possible to organize networking events, workshops, book speakers & more</h3>
                        </div>
                        <img src={Contributions} className="small" alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>


                <div className="container" >
                    <div className="container-grid-duo">
                        <div className="left">
                            <h2>Hire Diverse Talent</h2>
                            <h3>Source the right talent from within your company or get referrals from the people you already trust. SynERGie’s Opportunities Exchange is built to match employees across your organization to short gigs, key project, big roles, volunteering, learning or mentoring.</h3>

                        </div>
                        <img src={HireDiverse} alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>

                <div className="container" >
                    <div className="container-grid-duo switch-direction">
                        <div className="left">
                            <h2>Metrics for Growth</h2>
                            <h3>If it’s not being measured, it’s not being done. SynERGie automatically tracks event attendance, topics and their engagement, growth, surveys, votes, career development and involvement across department, members & allies.</h3>

                        </div>
                        <img src={Metrics} alt='Express yourself any way you choose. Text, audio or Video' loading='eager'/>
                    </div>
                </div>


                <div className="container container-gray" ref={ref => this.psedoRef = ref}>
                    <h1> <strong>Pseudonymous</strong>  </h1>
                    <h2> Not just a marketing gimmick. </h2>
                    <h3> We’re devoted to safeguarding our customers and employee private information and ensure the highest level of confidentiality of records. We take that a step further with pseudonymous.</h3>
                    <div className="container-grid">
                        <div className="grid-item">
                            <img src={GenerativeTextImage} alt='generate text' loading='eager'/>
                            <h3> <b> Generative Text </b></h3>
                            <h3> Auto complete your thoughts and become unrecognizable. </h3>
                        </div>
                        <div className="grid-item">
                            <img src={GenderChangeImage} alt='change voice genders' loading='eager'/>
                            <h3> <b> Sound different </b></h3>
                            <h3> Change the gender, age, bass and pitch of your voice. </h3>
                        </div>
                        <div className="grid-item">
                            <img src={DeepfakeImage} alt='deep fake videos' loading='eager'/>
                            <h3> <b> Deep Fake </b></h3>
                            <h3> Keep your identity hidden even on video. </h3>
                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="container-grid-duo-reverse">
                        <img id="fiLogo" src={FILogoImage} alt='Founder Institute' loading='eager'/>
                        <div className="selected">
                            <h1>Graduated from the World's Premier Pre-Seed Startup Incubator</h1>
                            <h3><a href="https://fi.co/" target="window">Fi.co</a></h3>
                        </div>
                    </div>
                </div>

                <div className="container container-light-gray" ref={ref => this.merchRef = ref}>
                    <div className="container-grid-duo-reverse">
                        <div className="merch">
                            <h1>New Merch!</h1>
                            <h3>Buy, wear and support us in a variety of ways</h3>
                            <CustomButton type="button" isVentButton visibility onClick={(event =>  window.location.href='https://my-store-cc4850.creator-spring.com/')}> Shop Here </CustomButton>
                        </div>
                        <img id="merchSpread" className="small" src={MerchSpread} alt='Synergie Merch' loading='eager'/>
                    </div>
                </div>

                <div className="container decorated" ref={ref => this.subscribeRef = ref}>
                    <div className="container-grid-duo">
                        <div className="left">
                            <h4> Stay Updated </h4>
                            <h2> Keep up to date with all the latest from SynERGie </h2>
                            <h3> Get notified when we launch our beta and receive weekly progress updates.</h3>
                            <div id="mc_embed_signup">
                                <form action="https://gmail.us6.list-manage.com/subscribe/post?u=7de28c2ff4808686d91ffa753&amp;id=9b47d51352" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
                                    <div id="mc_embed_signup_scroll">

                                        <div className="indicates-required"><span className="asterisk">*</span> indicates required</div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-EMAIL">Email Address
                                                    <span className="asterisk">*</span>
                                                </label>
                                                <input onChange={this.handleChange} type="email" value={this.state.EMAIL} name="EMAIL" className="required email" id="mce-EMAIL"/>
                                            </div>
                                            <div className="mc-field-group">
                                                    <label htmlFor="mce-FNAME">First Name </label>
                                                    <input onChange={this.handleChange} type="text" value={this.state.FNAME} name="FNAME" className="" id="mce-FNAME"/>
                                            </div>
                                            <div className="mc-field-group">
                                                <label htmlFor="mce-LNAME">Last Name </label>
                                                <input onChange={this.handleChange} type="text" value={this.state.LNAME} name="LNAME" className="" id="mce-LNAME"/>
                                            </div>
                                        </div>
                                        <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response" style={{display:'none'}}></div>
                                            <div className="response" id="mce-success-response" style={{display:'none'}}></div>
                                        </div>
                                        <div style={{position: 'absolute', left: '-5000px'}} aria-hidden="true"><input onChange={this.handleChange} type="text" name="b_7de28c2ff4808686d91ffa753_9b47d51352" tabIndex="-1" value=""/></div>
                                        <div className="clear"><input onSubmit={this.handleSubmit} type="submit" value="Let's Go" name="subscribe" id="mc-embedded-subscribe" className="button"/></div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div className='copyright'> © {(new Date().getFullYear())} SynERGie Tech, Inc </div>
                        <div className='links'>
                            {/* <Link className='option' to='/about'> About </Link> */}
                            {/* <Link className='option' to='/contact'> Contact </Link>
                            <Link className='option' to='/shop'> Terms and Conditions </Link>
                            <Link className='option' to='/contact'> Privacy Policy </Link> */}
                        </div>
                    </div>
                </div>
      </div>
        )
    }
}

export default withRouter(HomePage);

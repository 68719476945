import React from 'react'; 

import './policypage.styles.scss'; 

import { NavLink, withRouter } from 'react-router-dom';
import CustomButton from '../form/custom-button/custom-button.component';

import Logo from '../../assets/images/Logo_150x50Inverted.png';
import BannerImage from '../../assets/images/banner.jpeg';

class PolicyPage extends React.Component {
    render () {
        const { history } = this.props;
        return (
            <div className="policy-page">
                <div className='header'>
                    <NavLink to='/' className='logo-container'>
                        <img src={Logo} className='logo' alt='logo' loading='eager' height='50'/>
                    </NavLink>
                </div>

                <div className="banner">
                    <img className="banner-image" src={BannerImage} height="550" alt="Empower your diversity leaders today"/>
                    <video className="banner-video" height="550" autoPlay loop>
                        {/* <source src={}></source> */}
                    </video>
                    <div className="banner-text">
                        <h4>Legal</h4>
                        <h1>Privacy Policy</h1>
                        <CustomButton type="button" isVentButton visibility onClick={() => { history.replace('/') }}> Back to Home Page</CustomButton>
                    </div>
                </div>

                <div className="container">
                    <h3>Privacy Policy</h3>
                    <p>SynERGie is building an internal communication tool for Employee Resource Groups where members and allies can to share their concerns in a productive manner. They can even do so pseudonymously, creating a safe place to air ideas. With SynERGie, ERG leaders save time using a single tool to organize and engage members which they automatically track and can then share reports with upper management to acquire more resources for their groups. </p>
                </div>

                <div className="container container-gray">
                    <div className='footer'>
                        <div className='copyright'> © 2021 SynERGie Tech, Inc </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(PolicyPage);